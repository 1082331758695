import React from "react";
import SEO from "../components/seo/seo";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import FadeIn from "../components/animations/FadeIn";

export const query = graphql`query ($slug: String!) {
  allWeighingDevicesJson(slug: {eq: $slug}) {
    title
    subTitle
    characteristics
    about
    table {
      tableL
      tableR
    }
    compatibleHorizontal
    compatibleWithHorizontal {
      name
      linkTo
      compatibleWithHorizontalImage {
        childImageSharp {
          gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
    compatibleVertical
    compatibleWithVertical {
      name
      linkTo
      compatibleWithVerticalImage {
        childImageSharp {
          gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
    compatiblePl
    compatibleWithProcessLines {
      name
      linkTo
      compatibleWithPLImage {
        childImageSharp {
          gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
    image01 {
      childImageSharp {
        original {
          src
        }
      }
    }
    image02 {
      childImageSharp {
        original {
          src
        }
      }
    }
    image03 {
      childImageSharp {
        original {
          src
        }
      }
    }
  }
}
`;

const AllWeighingDevice = ({ data, intl }) => {
	const wDevice = data.allWeighingDevicesJson;

	const graphImage1 = wDevice.image01.childImageSharp.original.src;
	const graphImage2 = wDevice.image02.childImageSharp.original.src;
	const graphImage3 = wDevice.image03.childImageSharp.original.src;
	const images = [
		{
			original: graphImage1,
			thumbnail: graphImage1,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage2,
			thumbnail: graphImage2,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage3,
			thumbnail: graphImage3,
			originalAlt: 'machine Image'
		},
	];

	return <>
        <SEO title={intl.formatMessage({ id: "machineSelection.weighing" })} />

        {/* ----------------------------------------------------    H O M E     ------------------------------------------------ */}

        <div className="gridAllMachinesCreatePagesHome">
            <div className="boxAllMachinesCreatePagesHomeSideText">
                <FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
            </div>
            <div className="boxAllMachinesCreatePagesHomeTopText">
                <div className="allMachinesSecondLevelTitle">
                    <p>
                        <FormattedMessage id={wDevice.title} />
                    </p>
                </div>
                <div className="allMachinesSecondLevelSubTitle">
                    <div className="allAllMachinesWeighingDevicesCircle"></div>
                    <p className="allAllMachinesCircleText">
                        <FormattedMessage id={wDevice.subTitle} />
                    </p>
                </div>
            </div>
            <div className="boxAllMachinesCreatePagesHomeLeft">
                <ImageGallery
                    items={images}
                    lazyLoad={false}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    showThumbnails={false}
                    showNav={true}
                    showBullets={false}
                    autoPlay={false}
                    slideDuration={1000}
                    /*slideInterval={10000} */
                />
            </div>

            <div className="boxAllMachinesCreatePagesHomeRight">
                <div className="allAllMachinesTableWrapper">
                    <p className="allAllMachinesCharacteristics">
                        <FormattedMessage id={wDevice.characteristics} />
                    </p>
                    <table className="table">
                        <tbody>
                            {wDevice.table.map((item, index) => {
                                const { tableL, tableR } = item;
                                return (
                                    <tr key={index} className="allAllMachinesTable">
                                        <td className="allAllMachinesTableTextLeft">
                                            <FormattedMessage id={tableL} />
                                        </td>
                                        <td className="allAllMachinesTableTextRight">
                                            <FormattedMessage id={tableR} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="boxAllMachinesCreatePagesHomeEmptyRight"></div>
            <div className="boxAllMachinesCreatePagesHomeBottomText">
                <BottomFactory />
            </div>
        </div>

        <div className="allAllMachinesSection02">
            {/* ----------------------------------------------------    A B O U T  Section     ------------------------------------------------ */}
            <div className="allAllMachinesAboutBox">
                <div className="allAllMachinesMainTextWrapper">
                    <FormattedMessage id={wDevice.about} />
                </div>
            </div>

            {/* ----------------------------------------------------    canDoHorizontal Section     ------------------------------------------------ */}

            <div className="allMachinesFilterSection">
                {wDevice.compatibleWithHorizontal.some(() => {
                    const isTrue = [];
                    if (isTrue) {
                        return true;
                    }
                    return null;
                }) ? (
                    <div className="filterSectionInside">
                        <div className="filterSectionTitle">
                        <div className="allAllMachinesHorizontalCircle"></div>
                            <p>
                                <FormattedMessage id={wDevice.compatibleHorizontal} />
                            </p>
                        </div>
                        <div className="filterSectionMap">
                            {wDevice.compatibleWithHorizontal.map((item, index) => {
                                const { name, linkTo } = item;
                                return (
                                    <div
                                        key={index}
                                        className="filterSectionCard"
                                    >
                                        <Link to={linkTo}>
                                            <GatsbyImage
                                                image={item.compatibleWithHorizontalImage.childImageSharp.gatsbyImageData}
                                                className="filterSectionCardImage"
                                                alt="filterSectionCardImage"
                                                loading="eager" />
                                            <span className="filterSectionCardText">
                                                <FormattedMessage id={name} />
                                            </span>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    " "
                )}
            </div>

            {/* ----------------------------------------------------    canDoVertical Section     ------------------------------------------------ */}

            <div className="allMachinesFilterSection">
                {wDevice.compatibleWithVertical.some(() => {
                    const isTrue = [];
                    if (isTrue) {
                        return true;
                    }
                    return null;
                }) ? (
                    <div className="filterSectionInside">
                        <div className="filterSectionTitle">
                        <div className="allAllMachinesVerticalCircle"></div>
                            <p>
                                <FormattedMessage id={wDevice.compatibleVertical} />
                            </p>
                        </div>
                        <div className="filterSectionMap">
                            {wDevice.compatibleWithVertical.map((item, index) => {
                                const { name, linkTo } = item;
                                return (
                                    <div
                                        key={index}
                                        className="filterSectionCard"
                                    >
                                        <Link to={linkTo}>
                                            <GatsbyImage
                                                image={item.compatibleWithVerticalImage.childImageSharp.gatsbyImageData}
                                                className="filterSectionCardImage"
                                                alt="filterSectionCardImage"
                                                loading="eager" />
                                            <span className="filterSectionCardText">
                                                <FormattedMessage id={name} />
                                            </span>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    " "
                )}
            </div>

            {/* ----------------------------------------------------    MachinesPLCompatible Section     ------------------------------------------------ */}

            <div className="allMachinesFilterSection">
                {wDevice.compatibleWithProcessLines.some(() => {
                    const isTrue = [];
                    if (isTrue) {
                        return true;
                    }
                    return null;
                }) ? (
                    <div className="filterSectionInside">
                        <div className="filterSectionTitle">
                        <div className="allAllMachinesProcessCircle"></div>
                            <p>
                                <FormattedMessage id={wDevice.compatiblePl} />
                            </p>
                        </div>
                        <div className="filterSectionMap">
                            {wDevice.compatibleWithProcessLines.map((item, index) => {
                                const { name, linkTo } = item;
                                return (
                                    <div
                                        key={index}
                                        className="filterSectionCardProcess"
                                    >
                                        <Link to={linkTo}>
                                            <GatsbyImage
                                                image={item.compatibleWithPLImage.childImageSharp.gatsbyImageData}
                                                className="filterSectionCardProcessImage"
                                                alt="filterSectionCardImage"
                                                loading="eager" />
                                            <span className="filterSectionCardText">
                                                <FormattedMessage id={name} />
                                            </span>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    " "
                )}
            </div>
        </div>
    </>;
};

export default injectIntl(AllWeighingDevice);
